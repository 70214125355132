<template>
	<span>{{monthName}} {{yearName}}г.</span>
</template>

<script>
import {monthsShort, months} from "@/ittijs/Nomenclatures";

export default {
	props: {
		year: Number,
		month: Number,
		abbr: Boolean, // за име на месец
	},
	computed: {
		monthIndex(){
			let index = (this.month - 1) % 12;
			if (index < 0) index += 12;
			return index;
		},
		monthName(){
			return (this.abbr ? monthsShort : months)[this.monthIndex];
		},
		yearName(){
			return this.year + Math.floor(this.monthIndex / 12);
		},
	},
}
</script>