<template>
	<data-loader :callback="getData" :data.sync="data">
		<template #loading>
			<v-progress-linear indeterminate class="ma-4" rounded />
		</template>
		<template #default="{}">
			<v-simple-table dense>
				<thead>
				<tr>
					<th>Дружество</th>
					<th v-for="item in columns" :key="item.year * 12 + item.month" class="text-right">
						<year-month abbr :year="item.year" :month="item.month" />
					</th>
				</tr>
				</thead>
				<tbody>
				<tr v-for="(row,indexRow) in rows" :key="indexRow">
					<th>{{ row.utility }}</th>
					<td v-for="(cell, indexCell) in row.cells" :key="indexCell" class="text-right">
						{{ cell ? cell.price + ' лв.' : '' }}
					</td>
				</tr>
				</tbody>
			</v-simple-table>
		</template>
	</data-loader>
</template>

<script>
import DataLoader from "@/ittijs/components/DataLoader.vue";
import ITTIModelProvider from "@/ittijs/ITTIModelProvider";
import Model from "@/views/montaji/spravki/Model";
import YearMonth from "@/ittijs/components/YearMonth.vue";

const model = ITTIModelProvider.getModel(Model);

export default {
	components : {YearMonth, DataLoader},
	data() {
		return {
			data: null,
		}
	},
	computed: {
		columns() {
			if (!this.data) return [];
			let year = this.data.end_year;
			let month = this.data.end_month;
			const result = [];
			let limit = 12;
			while (limit >= 0 && year > this.data.start_year || year === this.data.start_year && month >= this.data.start_month) {
				limit -= 1;
				result.push({
					year, month
				});
				if (month === 1) {
					month = 12;
					year -= 1;
				}
				else {
					month -= 1;
				}
			}
			return result;
		},
		rows() {
			return this.groupItems(this.data?.records || [], ['utility_code', 'utility'])
				.map(item => ({
					utility: item.key.utility,
					cells: this.columns.map(
						column => item.items.find(
							record => record.year == column.year && record.month == column.month
						)
					),
				}));
		},
	},
	methods: {
		getData() {
			return model.fetch('getMonthlyData');
		},
		/**
		 * Group an array of objects by some of the item properties (specified by "fields")
		 * Result is array of objects, each object containing "key" and "items".
		 * "key" contains the field values, "items" is array of the original items that match the key.
		 */
		groupItems(items, fields) {
			const result = [];
			for (const item of items) {
				const group = result.find(
					searchItem => fields.every(
						field => searchItem.key[field] === item[field]
					)
				);
				if (group) {
					group.items.push(item);
				}
				else {
					result.push({
						key: Object.fromEntries(
							fields.map(field => [field, item[field]])
						),
						items: [item],
					})
				}
			}
			return result;
		},
	},
}
</script>