<template>
	<please-select-utility>
		<data-loader :watch="selectedUtility" :callback="getInitData">
			<template #loading>
				<v-progress-linear indeterminate class="ma-4" rounded />
			</template>
			<template #default="{data: initData}">
				<v-container fluid>
					<v-row>
						<v-col cols="3">
							<date-input v-model="dateStart" label="Начало" outlined dense hide-details />
						</v-col>
						<v-col cols="3">
							<date-input v-model="dateEnd" label="Край" outlined dense hide-details />
						</v-col>
						<v-col cols="3">
							<v-select v-model="group" label="Монтажна група" :items="initData?.optionsGroup" clearable outlined dense hide-details />
						</v-col>
						<v-col cols="3">
							<v-select v-model="users" label="Монтажници" :items="initData?.optionsUsers" multiple clearable outlined dense hide-details />
						</v-col>
					</v-row>
					<v-divider class="my-2" />
					<data-loader v-if="dateStart && dateEnd" :watch="{selectedUtility, dateStart, dateEnd, group, users}" :callback="getData">
						<template #loading>
							<v-progress-linear indeterminate class="ma-4" rounded />
						</template>
						<template #default="{data}">
							<ep-list v-model="expansion">
								<ep-panel v-if="data?.deviceModels?.length > 0">
									<ep-header>Монтирани уреди</ep-header>
									<ep-content>
										<v-simple-table dense>
											<thead>
											<tr>
												<th>Тип</th>
												<th>Марка</th>
												<th>Модел</th>
												<th>Радио</th>
												<th>Крипт.</th>
												<th class="text-right">Брой</th>
												<th class="text-right">Сума</th>
											</tr>
											</thead>
											<tbody>
											<tr v-for="(item, index) in data?.deviceModels" :key="index">
												<template v-if="item.id === null">
													<td class="font-weight-bold text-right" colspan="5">ОБЩО:</td>
													<td class="font-weight-bold text-right">{{item.count}}</td>
													<td class="font-weight-bold text-right">{{item.price_product_original}} лв.</td>
												</template>
												<template v-else>
													<td>{{DeviceTypeLabels.get(item.device_type_code)}}</td>
													<td>{{item.brand}}</td>
													<td>{{item.model}}</td>
													<td><boolean-indicator colored :value="item.is_radio==1" /></td>
													<td><boolean-indicator colored :value="item.is_encrypted==1" /></td>
													<td class="text-right">{{item.count}}</td>
													<td class="text-right">{{item.price_product_original}} лв.</td>
												</template>
											</tr>
											</tbody>
										</v-simple-table>
										<v-divider/>
									</ep-content>
								</ep-panel>
								<ep-panel v-if="data?.services?.length > 0">
									<ep-header>Извършени услуги</ep-header>
									<ep-content>
										<v-simple-table dense>
											<thead>
											<tr>
												<th></th>
												<th colspan="2" class="px-1"><div class="grey lighten-4 text-center pa-1">[без тип]</div></th>
												<th v-for="(label, key) in JobRequestPaymentTypeLabels" :key="key" colspan="2" class="px-1">
													<div class="grey lighten-4 text-center pa-1">{{label}}</div>
												</th>
												<th colspan="2" class="px-1"><div class="grey lighten-4 text-center pa-1">ОБЩО</div></th>
											</tr>
											<tr>
												<th>Услуга</th>
												<template v-for="index in Object.keys(JobRequestPaymentTypeLabels).length + 2">
													<th class="text-right" :key="'1'+index">Брой</th>
													<th class="text-right" :key="'2'+index">Сума (лв.)</th>
												</template>
											</tr>
											</thead>
											<tbody>
											<tr v-for="(item, index) in data?.services" :key="index">
												<template v-if="item.id === null">
													<td class="font-weight-bold text-right">ОБЩО:</td>
													<td class="font-weight-bold text-right">{{item.count_null}}</td>
													<td class="font-weight-bold text-right">{{item.price_null}}</td>
													<template v-for="ptype in Object.keys(JobRequestPaymentTypeLabels)">
													<td class="font-weight-bold text-right" :key="'1'+ptype">{{item['count_'+ptype]}}</td>
													<td class="font-weight-bold text-right" :key="'2'+ptype">{{item['price_'+ptype]}}</td>
													</template>
													<td class="font-weight-bold text-right">{{item.count}}</td>
													<td class="font-weight-bold text-right">{{item.price}}</td>
												</template>
												<template v-else>
													<td>{{item.title}}</td>
													<td class="text-right">{{item.count_null}}</td>
													<td class="text-right">{{item.price_null}}</td>
													<template v-for="ptype in Object.keys(JobRequestPaymentTypeLabels)">
													<td class="text-right" :key="'1'+ptype">{{item['count_'+ptype]}}</td>
													<td class="text-right" :key="'2'+ptype">{{item['price_'+ptype]}}</td>
													</template>
													<td class="text-right">{{item.count}}</td>
													<td class="text-right">{{item.price}}</td>
												</template>
											</tr>
											</tbody>
										</v-simple-table>
										<v-divider/>
									</ep-content>
								</ep-panel>
								<ep-panel v-if="data?.declined?.length > 0">
									<ep-header>Отказани услуги</ep-header>
									<ep-content>
										<v-simple-table dense>
											<thead>
											<tr>
												<th>Причина</th>
												<th class="text-right">Брой</th>
												<th class="text-right">Сума</th>
											</tr>
											</thead>
											<tbody>
											<tr v-for="(item, index) in data?.declined" :key="index">
												<template v-if="item.decline_reason === null">
													<td class="font-weight-bold text-right">ОБЩО:</td>
													<td class="font-weight-bold text-right">{{item.count}}</td>
													<td class="font-weight-bold text-right">{{item.price}} лв.</td>
												</template>
												<template v-else>
													<td>{{item.decline_reason}}</td>
													<td class="text-right">{{item.count}}</td>
													<td class="text-right">{{item.price}} лв.</td>
												</template>
											</tr>
											</tbody>
										</v-simple-table>
										<v-divider/>
									</ep-content>
								</ep-panel>
								<ep-panel v-if="data?.pricediff?.length > 0">
									<ep-header>Ценови разлики</ep-header>
									<ep-content>
										<v-simple-table dense>
											<thead>
											<tr>
												<th>ID на заявка</th>
												<th>Услуга</th>
												<th>Цена офис</th>
												<th>Цена монтажник</th>
												<th>Имот номер</th>
												<th>Адрес</th>
												<th>Статус</th>
												<th>Започната</th>
												<th>Монтажник</th>
											</tr>
											</thead>
											<tbody>
											<tr v-for="(item, index) in data.pricediff" :key="index">
												<td>{{ item.id }}</td>
												<td>{{ item.title }}</td>
												<td>{{ item.price1 }}</td>
												<td>{{ item.price2 }}</td>
												<td>{{ item.imot_N }}</td>
												<td>{{ item.address }}</td>
												<td><field-value :imodel="jobRequestsModel" name="status" :value="item.status" /></td>
												<td><date-time-format :datetime="item.time_started" /></td>
												<td>{{ item.user }}</td>
											</tr>
											</tbody>
										</v-simple-table>
									</ep-content>
								</ep-panel>
							</ep-list>
						</template>
					</data-loader>
				</v-container>
			</template>
		</data-loader>
	</please-select-utility>
</template>

<script>
import DateInput from "@/ittijs/Inputs/DateInput.vue";
import SelectedUtilityMixin from "@/ittijs/SelectedUtilityMixin";
import DataLoader from "@/ittijs/components/DataLoader.vue";
import ITTIModelProvider from "@/ittijs/ITTIModelProvider";
import Model from "./Model";
import JobRequestsModel from "../job_requests/Model";
import {phpDate} from "@/ittijs/utils";
import ExpansionPanelShort from "@/ittijs/ExpansionPanelShort";
import BooleanIndicator from "@/ittijs/components/BooleanIndicator.vue";
import {DeviceTypeLabels, JobRequestPaymentTypeLabels} from "@/ittijs/Nomenclatures";
import PleaseSelectUtility from "@/ittijs/components/PleaseSelectUtility.vue";
import FieldValue from "@/ittijs/components/FieldValue.vue";
import DateTimeFormat from "@/ittijs/components/DateTimeFormat.vue";

const model = ITTIModelProvider.getModel(Model);
const jobRequestsModel = ITTIModelProvider.getModel(JobRequestsModel);

export default {
	computed : {
		JobRequestPaymentTypeLabels() {
			return Object.fromEntries(JobRequestPaymentTypeLabels);
		},
		DeviceTypeLabels() {
			return DeviceTypeLabels
		},
	},
	mixins: [SelectedUtilityMixin, ExpansionPanelShort],
	components : {DateTimeFormat, FieldValue, PleaseSelectUtility, BooleanIndicator, DataLoader, DateInput},
	data(){
		return {
			dateStart: phpDate('Y-m-d'),
			dateEnd: phpDate('Y-m-d'),
			group: null,
			users: null,
			expansion: null,
			jobRequestsModel,
		}
	},
	methods: {
		getInitData() {
			return model.fetch('getPeriodInitData', {
				utility : this.selectedUtility,
			})
		},
		getData() {
			return model.fetch('getPeriodData', {
				utility   : this.selectedUtility,
				dateStart : this.dateStart,
				dateEnd   : this.dateEnd,
				group     : this.group,
				users     : this.users,
			})
		}
	},
}
</script>