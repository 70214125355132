<template>
	<span><template v-if="value !== null">{{ imodel.fields[name].model.formatView(value) }}</template></span>
	<!-- todo - what to do when null? -->
</template>

<script>
import IModelMixin from "@/ittijs/IModelMixin";
export default {
	mixins: [IModelMixin],
	props: ['name', 'value'],
}
</script>