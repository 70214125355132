<template>
  <v-icon v-bind="$attrs" v-on="$listeners" :color="computedColor">{{ value ? computedIconOn : computedIconOff }}</v-icon>
</template>

<script>
/**
 * Visual indicator for boolean values, uses icons (optionally colored) to
 * indicate true/false values.
 * Default icons are filled and rectangular, can be changed to round and/or
 * outlined, or simplified with "plain" to show just the tick/cross mark.
 */
export default {
  props: {
    iconOn: { // overrides plain, rounded and outlined
      type: String,
    },
    iconOff: { // overrides plain, rounded and outlined
      type: String,
    },
    colorOn: {
      type: String,
      default: 'success',
    },
    colorOff: {
      type: String,
      default: 'error',
    },
    plain: { // just the tick/cross, overrides rounded and outlined
      type: Boolean,
      default: false,
    },
    rounded: { // can be combined with outlined
      type: Boolean,
      default: false,
    },
    outlined: { // can be combined with rounded
      type: Boolean,
      default: false,
    },
    colored: {
      type: Boolean,
      default: false,
    },
    value: Boolean,
  },
  computed: {
    computedColor(){
      if (!this.colored) return null;
      return this.value ? this.colorOn : this.colorOff;
    },
    computedIconOn(){
      if (typeof this.iconOn !== 'undefined') return this.iconOn; // allow unsetting icon
      if (this.plain) return 'mdi-check';
      return this.rounded ? (
        this.outlined ? 'mdi-check-circle-outline' : 'mdi-check-circle'
      ) : (
        this.outlined ? 'mdi-checkbox-marked-outline' : 'mdi-checkbox-marked'
      );
    },
    computedIconOff(){
      if (typeof this.iconOff !== 'undefined') return this.iconOff; // allow unsetting icon
      if (this.plain) return 'mdi-close';
      return this.rounded ? (
        this.outlined ? 'mdi-close-circle-outline' : 'mdi-close-circle'
      ) : (
        this.outlined ? 'mdi-close-box-outline' : 'mdi-close-box'
      );
    },
  },
}
</script>