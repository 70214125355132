<template>
	<div>
		<v-tabs v-model="tabs" :background-color="$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-3'">
			<v-tab>За период</v-tab>
			<v-tab>Обобщена месечна</v-tab>
		</v-tabs>
		<v-container fluid>
			<v-tabs-items v-model="tabs">
				<v-tab-item>
					<page-period/>
				</v-tab-item>
				<v-tab-item>
					<page-monthly/>
				</v-tab-item>
			</v-tabs-items>
		</v-container>
	</div>
</template>

<script>
import PageMonthly from "@/views/montaji/spravki/PageMonthly.vue";
import PagePeriod from "@/views/montaji/spravki/PagePeriod.vue";

export default {
	components : {PagePeriod, PageMonthly},
	data() {
		return {
			tabs: null,
		}
	},
}
</script>