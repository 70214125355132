import {
	VExpansionPanels,
	VExpansionPanelHeader,
	VExpansionPanel,
	VExpansionPanelContent,
} from "vuetify/lib/components";

export default {
	components: {
		"ep-list"    : VExpansionPanels,
		"ep-header"  : VExpansionPanelHeader,
		"ep-content" : VExpansionPanelContent,
		"ep-panel"   : VExpansionPanel,
	}
}